class Holidays {
  constructor() {
    this.initCalendar()
  }

  initCalendar() {
    let self = this
    let calendarEl = document.getElementById('calendar')
    let calendar = new Calendar(calendarEl, {
      height: 600,
      plugins: [ dayGridPlugin, interactionPlugin ],
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'title',
        right: 'today prev,next'
      },
      locales: allLocales,
      locale: 'ja',
      eventSources: [{
        url:  Routes.holiday_details_master_holidays_path(),
        display: 'background',
        color: 'rgb(215, 10, 58)'
      }],
      dateClick: function(info) {
        self.handleShowHolidayDetailEvents(info, calendar)
      },
    });

    calendar.render()

    $('#year').on('change', function(e){
      let year = e.target.value
      if (!!year){
        calendar.gotoDate(new Date(year, 0))
      }else{
        calendar.gotoDate(new Date())
      }
    })

    $(document).on('click', '.fc-next-button, .fc-prev-button, .fc-today-button', function() {
      let date = calendar.getDate();
      let year = date.getFullYear()
      $('#year').val(year.toString())
    })
  }

  handleShowHolidayDetailEvents(info, calendar) {
    $.ajax({
      url: Routes.toggle_holiday_date_master_holidays_path(),
      data: {
        date: info.dateStr,
      },
      type: 'POST',
      dataType: 'JSON',
      success: function(resp) {
        calendar.refetchEvents()
      }
    })
  }

  static init() {
    new Holidays()
  }
}

export default Holidays