// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


window.jQuery = $
window.$ = $
import moment from 'moment'
window.moment = moment
moment.locale('ja')

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import allLocales from '@fullcalendar/core/locales-all'
import interactionPlugin from '@fullcalendar/interaction';
window.Calendar = Calendar
window.dayGridPlugin = dayGridPlugin
window.allLocales = allLocales
window.interactionPlugin = interactionPlugin

require("@rails/ujs").start()
require("@rails/activestorage").start()
const channels = require("channels")
require("@fortawesome/fontawesome-free/js/all")
require("javascripts/theme_plugins/bootstrap")
require("javascripts/adminlte")
require("javascripts/select2.min")
require('imports-loader?define=>false!datatables.net')(window, $)
require('imports-loader?define=>false!datatables.net-bs4')(window, $)
require('x-editable/dist/bootstrap3-editable/js/bootstrap-editable')
require("javascripts/dataTables.scroller.min")

$.fn.editable.defaults.mode = 'inline'
$.fn.editableform.buttons = '<button type="button" class="editable-cancel btn btn-default mr-2">cancel</button>' + '<button type="submit" class="editable-submit btn btn-primary">ok</button>'
$.fn.datepicker.DPGlobal.template = $.fn.datepicker.DPGlobal.template.replace(/icon-/g, "fa fa-");

import ProductRecord from 'pages/product_records'

// console.log(channels)
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function() {
  $('.on-document-ready').each((index, element) => {
    eval($(element).data('script'))
  })

  $(document).ready(function() {
    $('.scrollable').css('width', $('.main-scroll table').width() + 1);
    $('.main-scroll table').css('width', $('.main-scroll table').width() + 1);
  });

  $(window).resize(function() {
    $('.scrollable').css('width', $('.main-scroll table').width() + 1);
    $('.main-scroll table').css('width', $('.main-scroll table').width() + 1);
  });

  $('.scroll-helper').scroll(function() {
    $('.main-scroll').scrollLeft($('.scroll-helper').scrollLeft());
  });

  $('.main-scroll').scroll(function() {
    $('.scroll-helper').scrollLeft($('.main-scroll').scrollLeft());
  });
})

require("@nathanvda/cocoon")
import Holiday from 'pages/holidays'
import User from 'pages/users'
export { channels, ProductRecord, Holiday, User }
