import consumer from "./consumer"

const init = (productRecordId, userId) => {
  consumer.subscriptions.create(
    {
      channel: "ProductRecordChannel",
      product_record_id: productRecordId
    },
    {
      connected(data) {
        this.perform("set_editing", { editing_on: productRecordId, editing_by_user_id: userId })
      }
    }
  )
}

export { init }