class Users {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.userRoleEvent();
  }

  userRoleEvent() {
    $(".user-role-input")
      .on("change", (e) => {
        const role = e.target.value;

        this.handleShowHideDepartmentInput(role);
        this.handleShowHideSupplierInput(role);
      })
      .trigger("change");
  }

  handleShowHideDepartmentInput(role) {
    const departmentIdInput = $(".department-id-input");
    if (role === "supplier") {
      departmentIdInput.prop("disabled", true);
      departmentIdInput.closest("div").addClass("d-none");
    } else {
      departmentIdInput.prop("disabled", false);
      departmentIdInput.closest("div").removeClass("d-none");
    }
  }

  handleShowHideSupplierInput(role) {
    const supplierIdInput = $(".supplier-id-input");
    if (role === "supplier") {
      supplierIdInput.prop("disabled", false);
      supplierIdInput.closest("div").removeClass("d-none");
    } else {
      supplierIdInput.prop("disabled", true);
      supplierIdInput.closest("div").addClass("d-none");
    }
  }

  static init() {
    new Users();
  }
}

export default Users;
